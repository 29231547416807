import React from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import GuidePage from "../InfoPage/GuidePage";

export default function AQPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Accordo Quadro", url: "/aq" }]} />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-3">
            <h3>Accordo Quadro</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <p>
                A seguito dell’aggiudicazione del Lotto 1 “Servizi di Supporto
                per la Pubblica Amministrazione Centrale 1” della “Gara a procedura
                aperta per l’affidamento di un Accordo Quadro per ciascun lotto
                per l’erogazione di servizi professionali tecnici e di supporto
                all’adozione del cloud e PMO per le Pubbliche Amministrazioni (ID2652)”,
                Consip S.p.A. ha stipulato un Accordo Quadro con il RTI Aggiudicatario,
                composto dalle aziende {" "}
                <b>Deloitte Consulting S.r.l. S.B.</b>, ed <b>EY Advisory Spa</b>.
              </p>
              <h6>SERVIZI</h6>
              <p>I servizi oggetto del Lotto in oggetto sono:</p>
              <ul>
                <li>Assessment (S1)</li>
                <li>Strategia di Migrazione (S2) </li>
                <li>Studio di Fattibilità (S3) </li>
                <li>PMO (S4) </li>
              </ul>
              <h6>CHI PUÒ ADERIRE</h6>
              <p>
                Gli Enti che possono aderire al presente Accordo Quadro sono le Pubbliche Amministrazioni Centrali ricomprese nelle seguenti tipologie:
              </p>
              {/*<ul>
                <li>Campania</li>
                <li>Molise</li>
                <li>Basilicata</li>
                <li>Calabria</li>
                <li>Puglia</li>
                <li>Sicilia</li>
              </ul>*/}
              {/* <p> <b>•	Pubblica Amministrazione Centrale (PAC)</b></p>
              <ul>
                <li>Organi costituzionali e di rilievo costituzionale</li>
                <li>Presidenza del Consiglio dei Ministri</li>
                <li>Ministeri, ivi compresi gli Uffici Periferici</li>
                <li>Agenzie fiscali</li>
                <li>Enti di regolazione dell’attività economica</li>
                <li>Enti produttori di servizi economici</li>
                <li>Autorità amministrative indipendenti</li>
                <li>Enti a struttura associativa</li>
                <li>Enti produttori di servizi assistenziali, ricreativi e culturali</li>
                <li>Enti e Istituzioni di ricerca</li>
                <li>Enti nazionali di previdenza e assistenza sociale</li>
                <li>Commissari straordinari di governo</li>
                <li>Agenzia per i servizi sanitari regionali (AGENAS)</li>
                <li>Banca d’Italia</li>
                <li>Commissione Nazionale per le società e la borsa (CONSOB)</li>
                <li>Istituto per la vigilanza sulle assicurazioni (IVASS)</li>
                <li>Autorità per l’energia elettrica e il gas e il sistema idrico (AEEGSI)</li>
                <li>Enti pubblici esercenti attività di collegamento con le organizzazioni internazionali (enti che svolgono attività di collegamento tra il Governo italiano e le organizzazioni internazionali, quali a titolo meramente esemplificativo):
                  <ul>
                    <li>Comitato nazionale italiano Organizzazione Nazioni Unite per l’alimentazione e agricoltura (FAO)</li>
                    <li>Commissione Nazionale per l’Unesco</li>
                  </ul>
                </li>
                <li>Ordini professionali nazionali e relativi uffici periferici/collegi territoriali</li>
                <li>Ogni altra Amministrazione e/o Ente, di rilevanza nazionale;</li>
                <li>Gli Organismi di diritto pubblico e le Società, partecipati, anche indirettamente, dai soggetti di cui a tutti i punti precedenti, qualificabili come stazioni appaltanti (in caso di società partecipate da soggetti di tipologie diverse – es. partecipati contestualmente da soggetti rientranti nella PAC e da soggetti rientranti nella PAL – si intenderanno ricompresi nella PAC, ai fini della presente iniziativa, gli Organismi di diritto pubblico e le Società partecipati in misura maggioritaria, anche indirettamente, dai soggetti di cui ai precedenti punti).</li>
              </ul> */}
              <p> <b>•	Pubblica Amministrazione Centrale “1” – Ministeri</b></p>
              <ul>
                <li>Organi costituzionali e di rilievo costituzionale</li>
                <li>Presidenza del Consiglio dei Ministri</li>
                <li>Ministeri e Uffici periferici dei Ministeri</li>
                <li>Agenzie Fiscali</li>
                <li>Comitati interministeriali</li>
                <li>Commissari straordinari di governo</li>
                <li>Le società in house partecipate al 100% dai soggetti di cui ai punti precedenti.</li>
              </ul>
            </div>
            <div className="col-12 text-left">
              <GuidePage />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
