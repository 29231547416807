import React from "react";

export default function ConsipFooter() {
  return (
    <footer className="it-footer">
      <div className="it-footer-main">
        <div className="container">
          <div className="row py-4">
            <div
              className="col-12 col-md-4 text-left pb-3 pb-md-0"
              style={{ paddingTop: "10px" }}
            >
              {/* Per maggiori informazioni contattare:
              <b> consip.cloud.l6@it.ey.com</b> */}
            </div>
            
            <div
              className="col-6 col-md-3 text-left align-self-center"
              style={{ paddingTop: "20px" }}
            >
              <img
                className="d-none d-md-block"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture1.png"}
                height={30}
                alt="deloitte"
              />
              <img
                className="d-block d-md-none"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture1.png"}
                height={25}
                alt="deloitte"
              />
            </div>
            <div
              className="col-6 col-md-3 text-left align-self-center"
              style={{ paddingLeft: "104px", paddingBottom: "3px" }}
            >
              <img
                className="d-none d-md-block"
                src={process.env.PUBLIC_URL + "/assets/icons/EYLogoWhite.png"}
                height={55}
                alt="know"
              />
              <img
                className="d-block d-md-none"
                src={process.env.PUBLIC_URL + "/assets/icons/EYLogoWhite.png"}
                height={30}
                alt="know"
              />
            </div>
            {/*<div
              className="col-6 col-md-3 text-left align-self-center"
              style={{ paddingTop: "20px" }}
            >
              <img
                className="d-none d-md-block"
                src={process.env.PUBLIC_URL + "/assets/icons/commedia-Logo.png"}
                height={42}
                alt="know"
              />
              <img
                className="d-block d-md-none"
                src={process.env.PUBLIC_URL + "/assets/icons/commedia-Logo.png"}
                height={30}
                alt="know"
              />
            </div>
            */}
          </div>
        </div>
      </div>
    </footer>
  );
}